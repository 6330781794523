import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M979 1930 c-331 -32 -659 -156 -906 -345 l-42 -32 67 -66 67 -66 78
54 c527 362 1237 362 1764 0 l78 -54 67 66 67 66 -42 32 c-208 159 -490 279
-757 324 -139 23 -325 32 -441 21z"/>
<path d="M920 1505 c-181 -29 -389 -109 -530 -205 l-62 -42 67 -68 67 -68 44
30 c74 49 229 118 331 145 90 25 114 27 288 27 174 0 198 -2 288 -27 102 -27
257 -96 331 -145 l44 -30 67 68 67 68 -62 42 c-258 175 -624 255 -940 205z"/>
<path d="M1025 1100 c-99 -11 -213 -44 -298 -86 -109 -55 -107 -48 -31 -125
63 -63 70 -68 89 -55 38 25 161 64 240 76 132 20 277 -1 401 -59 l62 -29 67
68 67 68 -23 15 c-55 36 -184 88 -261 106 -101 23 -219 31 -313 21z"/>
<path d="M1065 688 c-116 -41 -165 -174 -103 -279 97 -165 353 -96 352 96 -1
133 -127 226 -249 183z"/>
</g>
</svg>



    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
